// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/remote_interface.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/remote_interface.proto.
 */
export const file_einride_rd_operator_interface_v1_remote_interface: GenFile = /*@__PURE__*/
  fileDesc("CjdlaW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS9yZW1vdGVfaW50ZXJmYWNlLnByb3RvEiBlaW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MSKyAgoPUmVtb3RlSW50ZXJmYWNlEhIKBG5hbWUYASABKAlCBOJBAQgSDwoHdmFyaWFudBgCIAEoCRIUCgxkaXNwbGF5X25hbWUYAyABKAkSFgoIbG9jYXRpb24YBCABKAlCBOJBAQESQgoGc3RhdHVzGAUgASgLMjIuZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuQ29ubmVjdGlvblN0YXR1czqHAepBgwEKOXJkLW9wZXJhdG9yLWludGVyZmFjZS5hcGkuZWlucmlkZS5zeXN0ZW1zL1JlbW90ZUludGVyZmFjZRIjcmVtb3RlSW50ZXJmYWNlcy97cmVtb3RlX2ludGVyZmFjZX0qEHJlbW90ZUludGVyZmFjZXMyD3JlbW90ZUludGVyZmFjZSKBAQoQQ29ubmVjdGlvblN0YXR1cxIfChFjb25uZWN0ZWRfdmVoaWNsZRgBIAEoCUIE4kEBARIVCgdzdGF0aW9uGAIgASgJQgTiQQEBEjUKC3VwZGF0ZV90aW1lGAMgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcEIE4kEBAWIGcHJvdG8z", [file_google_api_field_behavior, file_google_api_resource, file_google_protobuf_timestamp]);

/**
 * A RemoteInterface contains combined information about a RI's static attributes and if connected to an AV it also
 * contains information about what AV is is connected to.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterface
 */
export type RemoteInterface = Message<"einride.rd_operator_interface.v1.RemoteInterface"> & {
  /**
   * Pattern: remoteInterfaces/{remoteInterface}, where {remoteInterface} is a base32-encoded UUID.
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * Variant of the remote interface, e.g. Goodwood.
   *
   * @generated from field: string variant = 2;
   */
  variant: string;

  /**
   * Display name of the entity, e.g. "RI1000".
   *
   * @generated from field: string display_name = 3;
   */
  displayName: string;

  /**
   * Location of the stations.
   *
   * @generated from field: string location = 4;
   */
  location: string;

  /**
   * Connection status of the station.
   *
   * @generated from field: einride.rd_operator_interface.v1.ConnectionStatus status = 5;
   */
  status?: ConnectionStatus;
};

/**
 * Describes the message einride.rd_operator_interface.v1.RemoteInterface.
 * Use `create(RemoteInterfaceSchema)` to create a new message.
 */
export const RemoteInterfaceSchema: GenMessage<RemoteInterface> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface, 0);

/**
 * Connection status of the station.
 *
 * @generated from message einride.rd_operator_interface.v1.ConnectionStatus
 */
export type ConnectionStatus = Message<"einride.rd_operator_interface.v1.ConnectionStatus"> & {
  /**
   * Name of vehicle station is connected to.
   *
   * @generated from field: string connected_vehicle = 1;
   */
  connectedVehicle: string;

  /**
   * Name of the station reporting the connection status.
   *
   * @generated from field: string station = 2;
   */
  station: string;

  /**
   * Latest timestamp any connection status for the station was read.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
};

/**
 * Describes the message einride.rd_operator_interface.v1.ConnectionStatus.
 * Use `create(ConnectionStatusSchema)` to create a new message.
 */
export const ConnectionStatusSchema: GenMessage<ConnectionStatus> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface, 1);

