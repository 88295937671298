// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/path.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/path.proto.
 */
export const file_einride_rd_operator_interface_v1_path: GenFile = /*@__PURE__*/
  fileDesc("CitlaW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS9wYXRoLnByb3RvEiBlaW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MSK+AQoEUGF0aBISCgRuYW1lGAEgASgJQgTiQQEIEhoKDGRpc3BsYXlfbmFtZRgCIAEoCUIE4kEBAhISCgRwYXRoGAMgASgMQgTiQQECEhUKB3ZlcnNpb24YBCABKAlCBOJBAQI6W+pBWAoucmQtb3BlcmF0b3ItaW50ZXJmYWNlLmFwaS5laW5yaWRlLnN5c3RlbXMvUGF0aBIZc2l0ZXMve3NpdGV9L3BhdGhzL3twYXRofSoFcGF0aHMyBHBhdGhiBnByb3RvMw", [file_google_api_field_behavior, file_google_api_resource]);

/**
 * Path represents a path on WGS84-format.
 *
 * @generated from message einride.rd_operator_interface.v1.Path
 */
export type Path = Message<"einride.rd_operator_interface.v1.Path"> & {
  /**
   * Path resource name
   * Pattern: sites/{site}/paths/{path}.
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * Display name for the path.
   *
   * @generated from field: string display_name = 2;
   */
  displayName: string;

  /**
   * Geojson path, the format must correspond to the WSG84 format that the 'ad' UnmarshalGeoJSON function produces.
   * Said function can be found here: https://github.com/einride-autonomous/ads/blob/master/services/libs/geojson/unmarshal.go#L50-L95
   *
   * @generated from field: bytes path = 3;
   */
  path: Uint8Array;

  /**
   * Version corresponds to the WSG84 proto version used when creating the path. Available versions can be found here:
   * https://github.com/einride-autonomous/proto-aet/tree/master/src/ad
   *
   * @generated from field: string version = 4;
   */
  version: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Path.
 * Use `create(PathSchema)` to create a new message.
 */
export const PathSchema: GenMessage<Path> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_path, 0);

