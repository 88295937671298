import { errorMessages, okMessages, progressMessages } from "constants/actionCodes"
import { create } from "@bufbuild/protobuf"
import { useToasts } from "components/Toast"
import { Path } from "gen/einride/aet_path_service/v1"
import {
  Path as RoiPath,
  PathSchema as RoiPathSchema,
} from "gen/einride/rd_operator_interface/v1/path_pb"
import {
  RemoteInterfaceActionSchema,
  RemoteInterfaceAction_AssignPathActionSchema,
} from "gen/einride/rd_operator_interface/v1/remote_interface_action_pb"
import { CreateRemoteInterfaceActionRequestSchema } from "gen/einride/rd_operator_interface/v1/remote_interface_action_service_pb"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { useEffect, useState } from "react"
import { Region } from "../client"

import { useCreateRemoteInterfaceAction } from "./useCreateRemoteInterfaceAction"

interface Props {
  region: Region
  remoteInterface?: RemoteInterface
  vehicle?: Vehicle
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useVehicleAssignPath = (props: Props) => {
  const { region, remoteInterface, vehicle } = props

  const { toast } = useToasts()

  const [path, setPath] = useState<Path | null>(null)
  const [progress, setProgress] = useState<string | null>(null)

  const { mutateAsync, actionStatus, resetRequestId } = useCreateRemoteInterfaceAction()

  useEffect(() => {
    switch (actionStatus?.case) {
      case "error":
        toast({ status: "fail", message: errorMessages[actionStatus.value] })
        setPath(null)
        setProgress(null)
        resetRequestId()
        break
      case "ok":
        toast({ status: "success", message: okMessages[actionStatus.value] })
        setPath(null)
        setProgress(null)
        resetRequestId()
        break
      case "progress":
        setProgress(progressMessages[actionStatus.value])
        break
      default:
    }
  }, [actionStatus, resetRequestId, path?.displayName, toast])

  const assignPath = async (selectedPath: Path | undefined): Promise<void> => {
    if (!selectedPath || !remoteInterface || !vehicle) {
      return
    }

    try {
      setPath(selectedPath)
      setProgress("Starting path assign...")

      await mutateAsync({
        region,
        request: create(CreateRemoteInterfaceActionRequestSchema, {
          parent: remoteInterface.name,
          remoteInterfaceAction: create(RemoteInterfaceActionSchema, {
            remoteInterface: remoteInterface.name,
            vehicle: vehicle.name,
            action: {
              case: "assignPath",
              value: create(RemoteInterfaceAction_AssignPathActionSchema, {
                path: convertToROIPath(selectedPath),
              }),
            },
            requestingUser: "", // Overwritten in useCreateRemoteInterfaceAction hook def.
          }),
        }),
      })
    } catch (err) {
      toast({ status: "fail", message: `unknown assign path error: ${err}` })
      setPath(null)
    }
  }

  return {
    assignPath,
    isAssigningPath: !!progress,
    pathAssignProgress: progress,
  }
}

const convertToROIPath = (pathIn: Path): RoiPath => {
  const enc = new TextEncoder()
  return create(RoiPathSchema, {
    name: pathIn.name,
    displayName: pathIn.displayName,
    path: enc.encode(atob(pathIn.path || "")),
    version: pathIn.version,
  })
}
