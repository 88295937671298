import { Code, ConnectError, Transport, createCallbackClient } from "@connectrpc/connect"
import { useToasts } from "components/Toast"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { VehicleService } from "gen/einride/rd_operator_interface/v1/vehicle_service_pb"
import { useAPITransport } from "lib/api/hooks/useAPITransport"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Region } from "../client"

interface Props {
  region: Region
  name: string | undefined
  // Gets called every time a message arrives.
  onNewData: (data: StreamVehicleLiveDataResponse) => void
  // Gets called on end of stream, regardless of error.
  onStreamEnd?: (err?: ConnectError) => void
}

const MAX_RETRIES = 5
const MAX_SECONDS_BETWEEN_DATA = 30

export const useStreamVehicleLiveData = ({ name, onNewData, onStreamEnd, region }: Props): void => {
  const ts: Transport = useAPITransport(region)
  const client = useMemo(() => createCallbackClient(VehicleService, ts), [ts])
  const lastStreamMessageRef = useRef<Date | undefined>(undefined)
  const [retryAttempt, setRetryAttempt] = useState(0)
  const [isReconnecting, setIsReconnecting] = useState(false)
  const cancelStreamRef = useRef<(() => void) | null>(null)
  const toastIDRef = useRef<string>(Date.now().toString())

  const { toast, dismiss } = useToasts()

  const handleNewData = useCallback(
    (data: StreamVehicleLiveDataResponse) => {
      const now = new Date()
      lastStreamMessageRef.current = now
      if (retryAttempt > 0) {
        setRetryAttempt(0)
        setIsReconnecting(false)
        // Dismiss any reconnecting toasts
        dismiss(toastIDRef.current)
      }
      onNewData(data)
    },
    [onNewData, retryAttempt, dismiss],
  )

  const handleStreamEnd = useCallback(
    (err?: ConnectError) => {
      if (err && retryAttempt < MAX_RETRIES) {
        setRetryAttempt((prev) => prev + 1)
        setIsReconnecting(true)
      } else if (retryAttempt >= MAX_RETRIES) {
        toast({
          message: "Failed to reconnect after multiple attempts",
          status: "fail",
          id: toastIDRef.current,
          keepCount: false,
        })
        setIsReconnecting(false)
      }

      if (err) {
        // The missing trailer error occurs when the stream is abruptly ended due to an error not in the backend.
        // The API-Gateway has a timeout of 300 seconds and when that timeout hits, it just kills the stream and
        // the backend doesn't send it's last trailer bits (i.e. the stream is not properly ended by the backend).
        // So if we get the missing trailer error, we should just try to reconnect.
        // This is not shown to the user because it isn't a "real" error.
        if (err.code !== Code.DeadlineExceeded && err.message !== "missing trailer") {
          toast({
            status: "fail",
            message: `live data: ${err.message ?? "unknown livedata error"}`,
          })
        }
      }
      if (onStreamEnd) onStreamEnd(err)
    },
    [onStreamEnd, retryAttempt, toast],
  )

  useEffect(() => {
    const checkMessageTime = (): void => {
      if (!lastStreamMessageRef.current || isReconnecting) return
      const now = new Date()
      const diff = Math.round((now.getTime() - lastStreamMessageRef.current.getTime()) / 1000)

      // If we go more than MAX_SECONDS_BETWEEN_DATA without live data, attempt to reconnect
      if (diff > MAX_SECONDS_BETWEEN_DATA) {
        setRetryAttempt((prev) => prev + 1)
        setIsReconnecting(true)
        toast({
          message: `${diff} seconds since the last live data update. Attempting to reconnect...`,
          status: "fail",
          id: toastIDRef.current,
          keepCount: false,
        })
      } else {
        dismiss(toastIDRef.current)
      }
    }

    const interval = setInterval(checkMessageTime, 1000)
    return () => clearInterval(interval)
  }, [isReconnecting, toast, dismiss])

  useEffect(() => {
    if (!name || retryAttempt > MAX_RETRIES) {
      return undefined
    }

    const delay = 2 ** retryAttempt * 1000

    if (isReconnecting) {
      toast({
        message: `Attempting to reconnect (Attempt ${retryAttempt}/${MAX_RETRIES})...`,
        status: "fail",
        id: toastIDRef.current,
        keepCount: false,
      })
    }

    const timeoutId = setTimeout(() => {
      if (cancelStreamRef.current) {
        cancelStreamRef.current()
      }
      const cancel = client.streamVehicleLiveData({ name }, handleNewData, handleStreamEnd)
      cancelStreamRef.current = cancel
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [client, name, retryAttempt, isReconnecting, handleNewData, handleStreamEnd, toast])

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (cancelStreamRef.current) {
        cancelStreamRef.current()
      }
    }
  }, [])
}
