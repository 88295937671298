import { ConnectError } from "@connectrpc/connect"
import { useQuery } from "@tanstack/react-query"
import { GetRemoteInterfaceRequest } from "gen/einride/rd_operator_interface/v1"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { RemoteInterfaceService } from "gen/einride/rd_operator_interface/v1/remote_interface_service_pb"
import { Region } from "../client"
import { useRegionalizedConnectClient } from "./useAPITransport"

const STALE_TIME_MS = 1000 * 60 * 10 // 10 minutes

interface Props {
  region: Region
  req: GetRemoteInterfaceRequest
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetRemoteInterface = (props?: Props) => {
  const { region = "REGION_UNSPECIFIED", req } = props || {}
  const client = useRegionalizedConnectClient(RemoteInterfaceService, region)

  const query = useQuery<RemoteInterface, ConnectError>(
    ["getRemoteInterface", req, region],
    (): Promise<RemoteInterface> => {
      if (!req?.name) throw new Error("no remoteinterface name")

      return client.getRemoteInterface({ ...req })
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_MS,
      refetchInterval: 1000,
    },
  )
  return {
    ...query,
    data: query.data,
  }
}
