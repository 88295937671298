import { DescService } from "@bufbuild/protobuf"
import { Client, Interceptor, Transport, createClient } from "@connectrpc/connect"
import { createGrpcWebTransport } from "@connectrpc/connect-web"
import { useAuth } from "contexts/AuthContext"
import { useMemo } from "react"
import { Region } from "../client"

export function useAPITransport(region: Region): Transport {
  const { token } = useAuth()

  return useMemo(() => {
    const authorizer: Interceptor = (next) => async (req) => {
      const t = await token()
      req.header.set("Authorization", `Bearer ${t}`)
      return next(req)
    }

    return createGrpcWebTransport({
      baseUrl: getRegionUrl(region),
      useBinaryFormat: true,
      interceptors: [authorizer],
    })
  }, [region, token])
}

export function useRegionalizedConnectClient<T extends DescService>(
  service: T,
  region: Region,
): Client<T> {
  const transport = useAPITransport(region)
  // We memoize the client, so that we only create one instance per service.
  return useMemo(() => createClient(service, transport), [service, transport])
}

const getRegionUrl = (region: Region): string => {
  return region === "US"
    ? import.meta.env.VITE_ROI_BASE_URI_US
    : import.meta.env.VITE_ROI_BASE_URI_EU
}
