// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/remote_interface_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { ConnectionStatus, ConnectionStatusSchema, RemoteInterface, RemoteInterfaceSchema } from "./remote_interface_pb";
import { file_einride_rd_operator_interface_v1_remote_interface } from "./remote_interface_pb";
import { file_google_api_annotations } from "../../../google/api/annotations_pb";
import { file_google_api_client } from "../../../google/api/client_pb";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/remote_interface_service.proto.
 */
export const file_einride_rd_operator_interface_v1_remote_interface_service: GenFile = /*@__PURE__*/
  fileDesc("Cj9laW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS9yZW1vdGVfaW50ZXJmYWNlX3NlcnZpY2UucHJvdG8SIGVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxIuwBCh1DcmVhdGVDb25uZWN0aW9uU3RhdHVzUmVxdWVzdBJSCgZwYXJlbnQYASABKAlCQuJBAQL6QTsKOXJkLW9wZXJhdG9yLWludGVyZmFjZS5hcGkuZWlucmlkZS5zeXN0ZW1zL1JlbW90ZUludGVyZmFjZRIiChRjb25uZWN0aW9uX3N0YXR1c19pZBgCIAEoCUIE4kEBARJTChFjb25uZWN0aW9uX3N0YXR1cxgDIAEoCzIyLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLkNvbm5lY3Rpb25TdGF0dXNCBOJBAQIibQoZR2V0UmVtb3RlSW50ZXJmYWNlUmVxdWVzdBJQCgRuYW1lGAEgASgJQkLiQQEC+kE7CjlyZC1vcGVyYXRvci1pbnRlcmZhY2UuYXBpLmVpbnJpZGUuc3lzdGVtcy9SZW1vdGVJbnRlcmZhY2UiUAobTGlzdFJlbW90ZUludGVyZmFjZXNSZXF1ZXN0EhcKCXBhZ2Vfc2l6ZRgBIAEoBUIE4kEBARIYCgpwYWdlX3Rva2VuGAIgASgJQgTiQQEBIoUBChxMaXN0UmVtb3RlSW50ZXJmYWNlc1Jlc3BvbnNlEkwKEXJlbW90ZV9pbnRlcmZhY2VzGAEgAygLMjEuZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuUmVtb3RlSW50ZXJmYWNlEhcKD25leHRfcGFnZV90b2tlbhgCIAEoCTKqBQoWUmVtb3RlSW50ZXJmYWNlU2VydmljZRKyAQoSR2V0UmVtb3RlSW50ZXJmYWNlEjsuZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuR2V0UmVtb3RlSW50ZXJmYWNlUmVxdWVzdBoxLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlJlbW90ZUludGVyZmFjZSIs2kEEbmFtZYLT5JMCHxIdL3YxL3tuYW1lPXJlbW90ZUludGVyZmFjZXMvKn0SswEKFExpc3RSZW1vdGVJbnRlcmZhY2VzEj0uZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuTGlzdFJlbW90ZUludGVyZmFjZXNSZXF1ZXN0Gj4uZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuTGlzdFJlbW90ZUludGVyZmFjZXNSZXNwb25zZSIcgtPkkwIWEhQvdjEvcmVtb3RlSW50ZXJmYWNlcxKMAgoWQ3JlYXRlQ29ubmVjdGlvblN0YXR1cxI/LmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLkNyZWF0ZUNvbm5lY3Rpb25TdGF0dXNSZXF1ZXN0GjIuZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuQ29ubmVjdGlvblN0YXR1cyJ92kEtcGFyZW50LGNvbm5lY3Rpb25fc3RhdHVzLGNvbm5lY3Rpb25fc3RhdHVzX2lkgtPkkwJHOhFjb25uZWN0aW9uX3N0YXR1cyIyL3YxL3twYXJlbnQ9cmVtb3RlSW50ZXJmYWNlcy8qfS9jb25uZWN0aW9uU3RhdHVzZXMaFspBE2FwaS5laW5yaWRlLnN5c3RlbXNiBnByb3RvMw", [file_einride_rd_operator_interface_v1_remote_interface, file_google_api_annotations, file_google_api_client, file_google_api_field_behavior, file_google_api_resource]);

/**
 * Request to create a connection status
 *
 * @generated from message einride.rd_operator_interface.v1.CreateConnectionStatusRequest
 */
export type CreateConnectionStatusRequest = Message<"einride.rd_operator_interface.v1.CreateConnectionStatusRequest"> & {
  /**
   * The resource name of the parent remote interface
   *
   * @generated from field: string parent = 1;
   */
  parent: string;

  /**
   * ID of the connection status
   *
   * @generated from field: string connection_status_id = 2;
   */
  connectionStatusId: string;

  /**
   * The connection status of the station
   *
   * @generated from field: einride.rd_operator_interface.v1.ConnectionStatus connection_status = 3;
   */
  connectionStatus?: ConnectionStatus;
};

/**
 * Describes the message einride.rd_operator_interface.v1.CreateConnectionStatusRequest.
 * Use `create(CreateConnectionStatusRequestSchema)` to create a new message.
 */
export const CreateConnectionStatusRequestSchema: GenMessage<CreateConnectionStatusRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface_service, 0);

/**
 * Request to GetRemoteInterface.
 *
 * @generated from message einride.rd_operator_interface.v1.GetRemoteInterfaceRequest
 */
export type GetRemoteInterfaceRequest = Message<"einride.rd_operator_interface.v1.GetRemoteInterfaceRequest"> & {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.GetRemoteInterfaceRequest.
 * Use `create(GetRemoteInterfaceRequestSchema)` to create a new message.
 */
export const GetRemoteInterfaceRequestSchema: GenMessage<GetRemoteInterfaceRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface_service, 1);

/**
 * Request to ListRemoteInterfaces.
 *
 * @generated from message einride.rd_operator_interface.v1.ListRemoteInterfacesRequest
 */
export type ListRemoteInterfacesRequest = Message<"einride.rd_operator_interface.v1.ListRemoteInterfacesRequest"> & {
  /**
   * Requested page size. Server may return fewer results than requested.
   * If unspecified, at most 100 results will be returned.
   * The maximum value is 1000.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize: number;

  /**
   * A token identifying a page of results the server should return.
   * Typically, this is the value of `next_page_token` returned from
   * the previous call to `ListRemoteInterfaces` method.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.ListRemoteInterfacesRequest.
 * Use `create(ListRemoteInterfacesRequestSchema)` to create a new message.
 */
export const ListRemoteInterfacesRequestSchema: GenMessage<ListRemoteInterfacesRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface_service, 2);

/**
 * Response from ListRemoteInterfaces.
 *
 * @generated from message einride.rd_operator_interface.v1.ListRemoteInterfacesResponse
 */
export type ListRemoteInterfacesResponse = Message<"einride.rd_operator_interface.v1.ListRemoteInterfacesResponse"> & {
  /**
   * The list of ListRemoteInterfaces.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.RemoteInterface remote_interfaces = 1;
   */
  remoteInterfaces: RemoteInterface[];

  /**
   * A token to retrieve next page of results. Pass this value in the `page_token`
   * field in the subsequent call to `ListRemoteInterfaces` method to retrieve the next
   * page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.ListRemoteInterfacesResponse.
 * Use `create(ListRemoteInterfacesResponseSchema)` to create a new message.
 */
export const ListRemoteInterfacesResponseSchema: GenMessage<ListRemoteInterfacesResponse> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_remote_interface_service, 3);

/**
 * Remote interface service.
 *
 * @generated from service einride.rd_operator_interface.v1.RemoteInterfaceService
 */
export const RemoteInterfaceService: GenService<{
  /**
   * Get a remote interface.
   *
   * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.GetRemoteInterface
   */
  getRemoteInterface: {
    methodKind: "unary";
    input: typeof GetRemoteInterfaceRequestSchema;
    output: typeof RemoteInterfaceSchema;
  },
  /**
   * List remote interfaces.
   * (-- api-linter: core::0132::method-signature=disabled --)
   * (-- api-linter: core::0132::http-uri-parent=disabled --)
   *
   * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.ListRemoteInterfaces
   */
  listRemoteInterfaces: {
    methodKind: "unary";
    input: typeof ListRemoteInterfacesRequestSchema;
    output: typeof ListRemoteInterfacesResponseSchema;
  },
  /**
   * Endpoint for connection status updates, usually from ri-manager
   *
   * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.CreateConnectionStatus
   */
  createConnectionStatus: {
    methodKind: "unary";
    input: typeof CreateConnectionStatusRequestSchema;
    output: typeof ConnectionStatusSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_einride_rd_operator_interface_v1_remote_interface_service, 0);

