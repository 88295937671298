// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/vehicle_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Vehicle, VehicleSchema } from "./vehicle_pb";
import { file_einride_rd_operator_interface_v1_vehicle } from "./vehicle_pb";
import type { StreamVehicleLiveDataResponseSchema } from "./vehicle_live_pb";
import { file_einride_rd_operator_interface_v1_vehicle_live } from "./vehicle_live_pb";
import { file_google_api_annotations } from "../../../google/api/annotations_pb";
import { file_google_api_client } from "../../../google/api/client_pb";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/vehicle_service.proto.
 */
export const file_einride_rd_operator_interface_v1_vehicle_service: GenFile = /*@__PURE__*/
  fileDesc("CjZlaW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS92ZWhpY2xlX3NlcnZpY2UucHJvdG8SIGVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxIl0KEUdldFZlaGljbGVSZXF1ZXN0EkgKBG5hbWUYASABKAlCOuJBAQL6QTMKMXJkLW9wZXJhdG9yLWludGVyZmFjZS5hcGkuZWlucmlkZS5zeXN0ZW1zL1ZlaGljbGUiSAoTTGlzdFZlaGljbGVzUmVxdWVzdBIXCglwYWdlX3NpemUYASABKAVCBOJBAQESGAoKcGFnZV90b2tlbhgCIAEoCUIE4kEBASJsChRMaXN0VmVoaWNsZXNSZXNwb25zZRI7Cgh2ZWhpY2xlcxgBIAMoCzIpLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlZlaGljbGUSFwoPbmV4dF9wYWdlX3Rva2VuGAIgASgJImgKHFN0cmVhbVZlaGljbGVMaXZlRGF0YVJlcXVlc3QSSAoEbmFtZRgBIAEoCUI64kEBAvpBMwoxcmQtb3BlcmF0b3ItaW50ZXJmYWNlLmFwaS5laW5yaWRlLnN5c3RlbXMvVmVoaWNsZTKiBAoOVmVoaWNsZVNlcnZpY2USkgEKCkdldFZlaGljbGUSMy5laW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MS5HZXRWZWhpY2xlUmVxdWVzdBopLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlZlaGljbGUiJNpBBG5hbWWC0+STAhcSFS92MS97bmFtZT12ZWhpY2xlcy8qfRKTAQoMTGlzdFZlaGljbGVzEjUuZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuTGlzdFZlaGljbGVzUmVxdWVzdBo2LmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLkxpc3RWZWhpY2xlc1Jlc3BvbnNlIhSC0+STAg4SDC92MS92ZWhpY2xlcxLMAQoVU3RyZWFtVmVoaWNsZUxpdmVEYXRhEj4uZWlucmlkZS5yZF9vcGVyYXRvcl9pbnRlcmZhY2UudjEuU3RyZWFtVmVoaWNsZUxpdmVEYXRhUmVxdWVzdBo/LmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlN0cmVhbVZlaGljbGVMaXZlRGF0YVJlc3BvbnNlIjDaQQRuYW1lgtPkkwIjOgEqIh4vdjEve25hbWU9dmVoaWNsZXMvKn0vbGl2ZURhdGEwARoWykETYXBpLmVpbnJpZGUuc3lzdGVtc2IGcHJvdG8z", [file_einride_rd_operator_interface_v1_vehicle, file_einride_rd_operator_interface_v1_vehicle_live, file_google_api_annotations, file_google_api_client, file_google_api_field_behavior, file_google_api_resource]);

/**
 * Request to GetVehicle.
 *
 * @generated from message einride.rd_operator_interface.v1.GetVehicleRequest
 */
export type GetVehicleRequest = Message<"einride.rd_operator_interface.v1.GetVehicleRequest"> & {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.GetVehicleRequest.
 * Use `create(GetVehicleRequestSchema)` to create a new message.
 */
export const GetVehicleRequestSchema: GenMessage<GetVehicleRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_service, 0);

/**
 * Request to ListVehicles.
 *
 * @generated from message einride.rd_operator_interface.v1.ListVehiclesRequest
 */
export type ListVehiclesRequest = Message<"einride.rd_operator_interface.v1.ListVehiclesRequest"> & {
  /**
   * Requested page size. Server may return fewer results than requested.
   * If unspecified, at most 100 results will be returned.
   * The maximum value is 1000.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize: number;

  /**
   * A token identifying a page of results the server should return.
   * Typically, this is the value of `next_page_token` returned from
   * the previous call to `ListVehicles` method.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.ListVehiclesRequest.
 * Use `create(ListVehiclesRequestSchema)` to create a new message.
 */
export const ListVehiclesRequestSchema: GenMessage<ListVehiclesRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_service, 1);

/**
 * Response from ListVehicles.
 *
 * @generated from message einride.rd_operator_interface.v1.ListVehiclesResponse
 */
export type ListVehiclesResponse = Message<"einride.rd_operator_interface.v1.ListVehiclesResponse"> & {
  /**
   * The list of ListVehicles.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vehicle vehicles = 1;
   */
  vehicles: Vehicle[];

  /**
   * A token to retrieve next page of results. Pass this value in the `page_token`
   * field in the subsequent call to `ListVehicles` method to retrieve the next
   * page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.ListVehiclesResponse.
 * Use `create(ListVehiclesResponseSchema)` to create a new message.
 */
export const ListVehiclesResponseSchema: GenMessage<ListVehiclesResponse> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_service, 2);

/**
 * Request to StreamVehicleLiveDataResponse.
 *
 * @generated from message einride.rd_operator_interface.v1.StreamVehicleLiveDataRequest
 */
export type StreamVehicleLiveDataRequest = Message<"einride.rd_operator_interface.v1.StreamVehicleLiveDataRequest"> & {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.StreamVehicleLiveDataRequest.
 * Use `create(StreamVehicleLiveDataRequestSchema)` to create a new message.
 */
export const StreamVehicleLiveDataRequestSchema: GenMessage<StreamVehicleLiveDataRequest> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_service, 3);

/**
 * Vehicle service.
 *
 * @generated from service einride.rd_operator_interface.v1.VehicleService
 */
export const VehicleService: GenService<{
  /**
   * Get a vehicle.
   *
   * @generated from rpc einride.rd_operator_interface.v1.VehicleService.GetVehicle
   */
  getVehicle: {
    methodKind: "unary";
    input: typeof GetVehicleRequestSchema;
    output: typeof VehicleSchema;
  },
  /**
   * List vehicles.
   * (-- api-linter: core::0132::method-signature=disabled --)
   * (-- api-linter: core::0132::http-uri-parent=disabled --)
   *
   * @generated from rpc einride.rd_operator_interface.v1.VehicleService.ListVehicles
   */
  listVehicles: {
    methodKind: "unary";
    input: typeof ListVehiclesRequestSchema;
    output: typeof ListVehiclesResponseSchema;
  },
  /**
   * Stream vehicle live data.
   *
   * @generated from rpc einride.rd_operator_interface.v1.VehicleService.StreamVehicleLiveData
   */
  streamVehicleLiveData: {
    methodKind: "server_streaming";
    input: typeof StreamVehicleLiveDataRequestSchema;
    output: typeof StreamVehicleLiveDataResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_einride_rd_operator_interface_v1_vehicle_service, 0);

