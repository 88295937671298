// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/vehicle_live.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { LatLng } from "../../../google/type/latlng_pb";
import { file_google_type_latlng } from "../../../google/type/latlng_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/vehicle_live.proto.
 */
export const file_einride_rd_operator_interface_v1_vehicle_live: GenFile = /*@__PURE__*/
  fileDesc("CjNlaW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS92ZWhpY2xlX2xpdmUucHJvdG8SIGVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxIrUBCh1TdHJlYW1WZWhpY2xlTGl2ZURhdGFSZXNwb25zZRJDCghwb3NpdGlvbhgBIAEoCzIxLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlZlaGljbGVQb3NpdGlvbhIVCgdiYXR0ZXJ5GAQgASgCQgTiQQEBEhIKBHBhdGgYBSABKAxCBOJBAQESGAoKdHJhamVjdG9yeRgGIAEoDEIE4kEBAUoECAIQA0oECAMQBCKOAQoPVmVoaWNsZVBvc2l0aW9uEi0KCmNvb3JkaW5hdGUYASABKAsyEy5nb29nbGUudHlwZS5MYXRMbmdCBOJBAQESNQoLdXBkYXRlX3RpbWUYAiABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wQgTiQQEBEhUKB2hlYWRpbmcYAyABKAJCBOJBAQEiKQoGVmVjdG9yEgkKAXgYASABKAISCQoBeRgCIAEoAhIJCgF6GAMgASgCIksKB1BvbHlnb24SQAoOdmVydGljZXNfbWV0cmUYASADKAsyKC5laW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MS5WZWN0b3IiyQEKB09iamVjdHMSMAoMcHVibGlzaF90aW1lGAEgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBJGCgxvYmplY3RzX2Jhc2UYAyADKAsyMC5laW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MS5PYmplY3RzLk9iamVjdBpECgZPYmplY3QSOgoHcG9seWdvbhgBIAEoCzIpLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlBvbHlnb25iBnByb3RvMw", [file_google_api_field_behavior, file_google_protobuf_timestamp, file_google_type_latlng]);

/**
 * Response from StreamVehicleLiveDataResponse
 *
 * @generated from message einride.rd_operator_interface.v1.StreamVehicleLiveDataResponse
 */
export type StreamVehicleLiveDataResponse = Message<"einride.rd_operator_interface.v1.StreamVehicleLiveDataResponse"> & {
  /**
   * Position information of the vehicle.
   *
   * @generated from field: einride.rd_operator_interface.v1.VehiclePosition position = 1;
   */
  position?: VehiclePosition;

  /**
   * Battery level in percent.
   *
   * @generated from field: float battery = 4;
   */
  battery: number;

  /**
   * Base64 encoded geojson path that the vehicle is currently following
   *
   * @generated from field: bytes path = 5;
   */
  path: Uint8Array;

  /**
   * Base64 encoded geojson trajectory that the vehicle is currently following
   *
   * @generated from field: bytes trajectory = 6;
   */
  trajectory: Uint8Array;
};

/**
 * Describes the message einride.rd_operator_interface.v1.StreamVehicleLiveDataResponse.
 * Use `create(StreamVehicleLiveDataResponseSchema)` to create a new message.
 */
export const StreamVehicleLiveDataResponseSchema: GenMessage<StreamVehicleLiveDataResponse> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 0);

/**
 * Source: perception.v1.Egostate and perception.v1.UTMToLocalTransform.
 *
 * @generated from message einride.rd_operator_interface.v1.VehiclePosition
 */
export type VehiclePosition = Message<"einride.rd_operator_interface.v1.VehiclePosition"> & {
  /**
   * LatLng coordinate of vehicle.
   *
   * @generated from field: google.type.LatLng coordinate = 1;
   */
  coordinate?: LatLng;

  /**
   * Update time.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;

  /**
   * Heading in radians. Note: defaults to 0 when missing data.
   *
   * @generated from field: float heading = 3;
   */
  heading: number;
};

/**
 * Describes the message einride.rd_operator_interface.v1.VehiclePosition.
 * Use `create(VehiclePositionSchema)` to create a new message.
 */
export const VehiclePositionSchema: GenMessage<VehiclePosition> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 1);

/**
 * Vector/3D point structure.
 *
 * @generated from message einride.rd_operator_interface.v1.Vector
 */
export type Vector = Message<"einride.rd_operator_interface.v1.Vector"> & {
  /**
   * x (forward/back).
   *
   * @generated from field: float x = 1;
   */
  x: number;

  /**
   * y (left/right).
   *
   * @generated from field: float y = 2;
   */
  y: number;

  /**
   * y (up/down).
   *
   * @generated from field: float z = 3;
   */
  z: number;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Vector.
 * Use `create(VectorSchema)` to create a new message.
 */
export const VectorSchema: GenMessage<Vector> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 2);

/**
 * Polygon definition, repeated 3D points.
 *
 * @generated from message einride.rd_operator_interface.v1.Polygon
 */
export type Polygon = Message<"einride.rd_operator_interface.v1.Polygon"> & {
  /**
   * A list of vertices in meter.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vector vertices_metre = 1;
   */
  verticesMetre: Vector[];
};

/**
 * Describes the message einride.rd_operator_interface.v1.Polygon.
 * Use `create(PolygonSchema)` to create a new message.
 */
export const PolygonSchema: GenMessage<Polygon> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 3);

/**
 * Objects definition, repeated object polygons.
 *
 * @generated from message einride.rd_operator_interface.v1.Objects
 */
export type Objects = Message<"einride.rd_operator_interface.v1.Objects"> & {
  /**
   * Time the object list was published.
   *
   * @generated from field: google.protobuf.Timestamp publish_time = 1;
   */
  publishTime?: Timestamp;

  /**
   * Detected objects, expressed in base frame.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Objects.Object objects_base = 3;
   */
  objectsBase: Objects_Object[];
};

/**
 * Describes the message einride.rd_operator_interface.v1.Objects.
 * Use `create(ObjectsSchema)` to create a new message.
 */
export const ObjectsSchema: GenMessage<Objects> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 4);

/**
 * Object definition.
 *
 * @generated from message einride.rd_operator_interface.v1.Objects.Object
 */
export type Objects_Object = Message<"einride.rd_operator_interface.v1.Objects.Object"> & {
  /**
   * Polygon enveloping the object in XY-plane, expressed in base frame.
   * Polygons are concave and does not self-intersect.
   * Z-dimension is unused (always set to 0).
   *
   * @generated from field: einride.rd_operator_interface.v1.Polygon polygon = 1;
   */
  polygon?: Polygon;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Objects.Object.
 * Use `create(Objects_ObjectSchema)` to create a new message.
 */
export const Objects_ObjectSchema: GenMessage<Objects_Object> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle_live, 4, 0);

