import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import {
  ListVehiclesResponse,
  VehicleService,
} from "gen/einride/rd_operator_interface/v1/vehicle_service_pb"
import { useEffect, useState } from "react"
import { Region } from "../client"
import { useRegionalizedConnectClient } from "./useAPITransport"

const STALE_TIME_MS = 1000 * 60 * 10 // 10 minutes
const REFETCH_TIME_MS = 1000 * 10 // 10 seconds

interface Props {
  pageToken?: string
  pageSize?: number
  region: Region
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useListVehicles = (props?: Props) => {
  const { pageSize = 50, pageToken, region = "REGION_UNSPECIFIED" } = props || {}
  const client = useRegionalizedConnectClient(VehicleService, region)

  const query = useInfiniteQuery(
    ["ListVehicles", region, pageSize, pageToken],
    async (context): Promise<ListVehiclesResponse> => {
      try {
        return await client?.listVehicles({
          pageSize: props?.pageSize,
          pageToken: context.pageParam || pageToken,
        })
      } catch (err) {
        console.error(err)
        return {} as ListVehiclesResponse
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: REFETCH_TIME_MS,
      staleTime: STALE_TIME_MS,
      enabled: !!client,
      getNextPageParam: (page) => page?.nextPageToken || undefined,
    },
  )

  const [data, setData] = useState<Vehicle[]>([])
  useEffect(() => {
    setData(convertFromMapToList(query?.data))
  }, [query?.data])

  return {
    ...query,
    data,
  }
}

const convertFromMapToList = (data?: InfiniteData<ListVehiclesResponse | undefined>): Vehicle[] =>
  data?.pages.flatMap((page) => page?.vehicles || []) ?? []
