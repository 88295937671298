// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,ts_nocheck=true"
// @generated from file einride/rd_operator_interface/v1/vehicle.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { LatLng } from "../../../google/type/latlng_pb";
import { file_google_type_latlng } from "../../../google/type/latlng_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file einride/rd_operator_interface/v1/vehicle.proto.
 */
export const file_einride_rd_operator_interface_v1_vehicle: GenFile = /*@__PURE__*/
  fileDesc("Ci5laW5yaWRlL3JkX29wZXJhdG9yX2ludGVyZmFjZS92MS92ZWhpY2xlLnByb3RvEiBlaW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MSKkBAoHVmVoaWNsZRISCgRuYW1lGAEgASgJQgTiQQEIEhQKDGRpc3BsYXlfbmFtZRgCIAEoCRJECgVzdGF0ZRgDIAEoCzIvLmVpbnJpZGUucmRfb3BlcmF0b3JfaW50ZXJmYWNlLnYxLlZlaGljbGUuU3RhdGVCBOJBAQESSQoLZGVwbG95bWVudHMYBCADKAsyNC5laW5yaWRlLnJkX29wZXJhdG9yX2ludGVyZmFjZS52MS5WZWhpY2xlLkRlcGxveW1lbnQSFgoOcmVtb3RlX3ZlcnNpb24YBSABKAkaswEKBVN0YXRlEjgKDmxhc3Rfc2Vlbl90aW1lGAEgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcEIE4kEBARIxCg5sYXN0X3NlZW5fZ25zcxgCIAEoCzITLmdvb2dsZS50eXBlLkxhdExuZ0IE4kEBARI9ChNsYXN0X3NlZW5fZ25zc190aW1lGAMgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcEIE4kEBARoxCgpEZXBsb3ltZW50EhIKCmRlcGxveW1lbnQYASABKAkSDwoHdmVyc2lvbhgCIAEoCTpd6kFaCjFyZC1vcGVyYXRvci1pbnRlcmZhY2UuYXBpLmVpbnJpZGUuc3lzdGVtcy9WZWhpY2xlEhJ2ZWhpY2xlcy97dmVoaWNsZX0qCHZlaGljbGVzMgd2ZWhpY2xlYgZwcm90bzM", [file_google_api_field_behavior, file_google_api_resource, file_google_protobuf_timestamp, file_google_type_latlng]);

/**
 * Vehicle is a simplified version of the full message definition in aet-registry,
 * it only contains fields relevant to remote operator interface.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle
 */
export type Vehicle = Message<"einride.rd_operator_interface.v1.Vehicle"> & {
  /**
   * The resource name of the vehicle.
   * Pattern: vehicles/{vehicleId}, where vehicleId is a base32-encoded UUID.
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * display name of the entity. E.g "Bruce1"
   *
   * @generated from field: string display_name = 2;
   */
  displayName: string;

  /**
   * Ephemeral state fields goes here, like state of charge etc.
   *
   * @generated from field: einride.rd_operator_interface.v1.Vehicle.State state = 3;
   */
  state?: Vehicle_State;

  /**
   * System deployments.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vehicle.Deployment deployments = 4;
   */
  deployments: Vehicle_Deployment[];

  /**
   * Remote version (found remote-operate/oden-streamer version.
   *
   * @generated from field: string remote_version = 5;
   */
  remoteVersion: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Vehicle.
 * Use `create(VehicleSchema)` to create a new message.
 */
export const VehicleSchema: GenMessage<Vehicle> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle, 0);

/**
 * Basic last seen information.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle.State
 */
export type Vehicle_State = Message<"einride.rd_operator_interface.v1.Vehicle.State"> & {
  /**
   * When we last saw traffic from this pod.
   *
   * @generated from field: google.protobuf.Timestamp last_seen_time = 1;
   */
  lastSeenTime?: Timestamp;

  /**
   * The last location reported (may be older than last_seen_time).
   *
   * @generated from field: google.type.LatLng last_seen_gnss = 2;
   */
  lastSeenGnss?: LatLng;

  /**
   * Timestamp corresponding to the GNSS coordinates, may be (much) older than last_seen_time.
   *
   * @generated from field: google.protobuf.Timestamp last_seen_gnss_time = 3;
   */
  lastSeenGnssTime?: Timestamp;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Vehicle.State.
 * Use `create(Vehicle_StateSchema)` to create a new message.
 */
export const Vehicle_StateSchema: GenMessage<Vehicle_State> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle, 0, 0);

/**
 * Information about a sw deployment.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle.Deployment
 */
export type Vehicle_Deployment = Message<"einride.rd_operator_interface.v1.Vehicle.Deployment"> & {
  /**
   * Name of the deployed software.
   *
   * @generated from field: string deployment = 1;
   */
  deployment: string;

  /**
   * Version of the deployed software.
   *
   * @generated from field: string version = 2;
   */
  version: string;
};

/**
 * Describes the message einride.rd_operator_interface.v1.Vehicle.Deployment.
 * Use `create(Vehicle_DeploymentSchema)` to create a new message.
 */
export const Vehicle_DeploymentSchema: GenMessage<Vehicle_Deployment> = /*@__PURE__*/
  messageDesc(file_einride_rd_operator_interface_v1_vehicle, 0, 1);

