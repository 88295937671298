import { ConnectError } from "@connectrpc/connect"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAuth } from "contexts/AuthContext"
import {
  ActionResponse,
  RemoteInterfaceAction,
} from "gen/einride/rd_operator_interface/v1/remote_interface_action_pb"
import {
  CreateRemoteInterfaceActionRequest,
  RemoteInterfaceActionService,
} from "gen/einride/rd_operator_interface/v1/remote_interface_action_service_pb"
import { useEffect, useState } from "react"
import { Region } from "../client"
import { useRegionalizedConnectClient } from "./useAPITransport"

interface Props {
  region: Region
  request: CreateRemoteInterfaceActionRequest
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCreateRemoteInterfaceAction = (props?: Props) => {
  const [requestId, setRequestId] = useState<string | null>(null)
  const { region = "REGION_UNSPECIFIED" } = props || {}
  const remoteInterfaceActionClient = useRegionalizedConnectClient(
    RemoteInterfaceActionService,
    region,
  )

  const { user } = useAuth()

  const query = useQuery<ActionResponse, ConnectError>(
    ["getActionResponse", requestId, region],
    (): Promise<ActionResponse> => {
      if (!requestId) throw new Error("no request id to query with")

      return remoteInterfaceActionClient.getActionResponse({ name: requestId })
    },
    {
      enabled: !!remoteInterfaceActionClient && !!requestId,
      retry: false,
      refetchInterval: 1000,
    },
  )

  const mutation = useMutation(async ({ request }: Props) => {
    if (user === undefined) {
      return {} as RemoteInterfaceAction
    }
    if (request && request.remoteInterfaceAction) {
      request.remoteInterfaceAction.requestingUser = makeSupervisorSessionID(user.uid)
      request.remoteInterfaceActionId = createRequestID()
    } else {
      return {} as RemoteInterfaceAction
    }
    return remoteInterfaceActionClient.createRemoteInterfaceAction(request)
  })

  useEffect(() => {
    if (mutation.error) {
      setRequestId(null)
      query.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  const createRequestID = (): string => {
    const requestID = crypto.randomUUID()
    setRequestId(requestID)
    return requestID
  }

  const resetRequestId = (): void => {
    setRequestId(null)
    query.remove()
  }

  return { ...mutation, actionStatus: query.data?.status, resetRequestId }
}

export const makeSupervisorSessionID = (uid: string): string => {
  const sessionIdLength = 32
  if (uid.length > sessionIdLength) {
    return uid.substring(0, sessionIdLength)
  }
  const paddedUID = uid.padEnd(sessionIdLength, "=")
  return paddedUID
}
