import { ConnectError } from "@connectrpc/connect"
import { useQuery } from "@tanstack/react-query"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { VehicleService } from "gen/einride/rd_operator_interface/v1/vehicle_service_pb"
import { Region } from "../client"
import { useRegionalizedConnectClient } from "./useAPITransport"

interface Props {
  region: Region
  name: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetVehicle = (props?: Props) => {
  const { region = "REGION_UNSPECIFIED", name } = props || {}
  const client = useRegionalizedConnectClient(VehicleService, region)

  const query = useQuery<Vehicle, ConnectError>(
    ["getVehicle", name, region],
    (): Promise<Vehicle> => {
      if (!name) throw new Error("no vehicle name")

      return client.getVehicle({ name })
    },
    {
      enabled: !!client,
      retry: false,
    },
  )

  return {
    ...query,
    data: query.data,
  }
}
